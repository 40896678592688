.active {
    color: #1242DE;
}

.success {
    color: green;
}

.warning {
    color: yellow;
}