.kpi-card {
    display: flex;
    flex-wrap: wrap;
    max-width: calc(100% - 40px);
    margin: 0 auto;
}

.kpi-entry {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 20px;
    width: 150px;
}

.kpi-entry>:nth-child(1) {
    font-size:24px;
    font-weight: bold;
}

.kpi-entry>:nth-child(2) {
    font-size:20px;
}

.kpi-entry>:nth-child(3) {
    font-size:16px;
    color: #4B7902;
}