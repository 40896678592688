.story-card {
    display: flex;
    flex-direction: column;
    width: 216px;
    
    margin: 12px;
    padding: 0 !important;
}

.story-card img {
    width: 216px;
    height: 384px;
}

.story-card-info-block {
    display: flex;
    flex-direction: column;

    padding: 20px;
}

.story-card p {
    margin: 0;
}

p.story-card-label {
    margin-bottom:12px;
    color: grey;
    font-size: 12px;
}