/** following two imports are required for blueprintjs usage */
@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";

.App {
  text-align: center;
}

html {
  font-family: sans-serif;
}

body, td, th {
  font-size: 16px;
}

.content-page {
  padding: 20px;
}

.bp3-ui-text {
  font-size: 50px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

td.number {
  text-align: right;
}

.nav-bar-link {
  margin: 0 12px;
  color: black;
}

.nav-bar-link:focus {
  outline: none;
}

.nav-bar-link.active {
  font-weight: bold;
}