.scraper-tab:focus {
    outline: none;
}

.scraper-tab {
    color: black;
    margin: 8px 12px;
}

.scraper-tab.active {
    color: blue;
    text-decoration: underline;
}