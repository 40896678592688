.admin {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
}

.admin a {
  text-decoration: none;
}

.admin button {
  text-decoration: none;
  background: none;
  border: none;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
}

.tab {
  color: rgba(175,175,175,1);
  margin: 20px;
}

.tab:hover {
  color: white;
  cursor: pointer;
}

.tab.selected {
  color: white;
}

.admin tr {
  height: 55px;
}

.admin tbody tr.clickable:hover {
  background: rgba(50,50,50,1);
  cursor:pointer;
}

.admin td {
  text-align: center;
  max-width: 270px;
}



.admin.highlighted {
  color: #05ACEE;
}

.admin.reject {
  color: red
}

.sold {
  color: #ffbc29;
}

.admin button:hover {
  cursor: pointer;
  color: #AAA;
}

.admin a:hover {
  cursor: pointer;
  color: white;
}

.admin button:disabled {
  color: #777;
  cursor: auto;
}

.admin img {
  object-fit: contain;
  background: gray;
}

.admin input, .admin label {
  height: 30px;
  font-size: 100%;
}

.success {
  color: #0cfc2c;
}

#products_table td {
  padding: 10px;
}
