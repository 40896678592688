.avatar {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}

td.contain {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.deal-row-Done {
    background-color: #b6d7cb;
}

.deal-row-Pending {
    background-color: #a4c6da;
}

.deal-row-Expired {
    background-color: #e8b2b2;
}