.brand-info {
    width: 300px;
    height: 300px;
}

#cpv-table {
    margin: 1rem;
}

#cpv-table th, #cpv-table td {
    text-align: right;
}