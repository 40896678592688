.pagination-row {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-width: 320px;
}

.pagination-row p {
    margin: 0;
}