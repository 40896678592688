.review-card {
    width: 360px;
    height: 680px;
}

img.content, video.content {
    width: 320px;
    height: 569px;
}

.detail-row {
    display: flex;
    align-items: center;
    margin-bottom:5px;
}

.detail-row>:nth-child(2) {
    margin-left: 12px;
}

.detail-row h5 {
    margin: 0;
}

.detail-row-numbers {
    justify-content: space-between;
    width: 200px;
}

table.notifications td {
    text-align: center;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
}

.card-container>div {
    margin: 12px;
}